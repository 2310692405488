export default {
    en: {
        title: 'SIZE',
        intro: 'Your chosen size affects your design',
        info: 'Want to know how? Have a look at the sample socks',
    },
    nl: {
        title: 'MAAT',
        intro: 'De maat die je kiest heeft invloed op je design. Check de voorbeeldsokken in de ruimte voor de juiste maat. Indien je twijfelt kies \n' +
          'dan een maat die wat groter is, je sokken zullen bij de eerste keer wassen nog een klein beetje \n' +
          'krimpen.',
        info: 'Hoe precies? Bekijk de voorbeeldsokken'
    }
};
