

























/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { app } from '@config';
import { mapState } from 'vuex';
import PageMain from '@part/layout/PageMain.vue';
import InfoToast from '@part/elements/InfoToast.vue';
import lang from '@lang/size.js';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    components: {
        PageMain,
        InfoToast
    },
    props: [],
    data() {
        return {
            sizes: [
                {
                    size: 'S',
                    text: 'SMALL (36-38)'
                },{
                    size: 'M',
                    text: 'MEDIUM (39-41)'
                },{
                    size: 'L',
                    text: 'LARGE (42-44)'
                },{
                    size: 'XL',
                    text: 'EXTRA LARGE (45-46)'
                }
            ]
        };
    },
    computed: {
        lang(){
            return lang[this.activeLang()];
        },
        ...mapState(['sessionData']),
    }
})

/* ----------------------------------------
    Export
---------------------------------------- */
export default class Dev extends Vue {}
