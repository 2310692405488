













/* ----------------------------------------
    Imports
---------------------------------------- */

import { Component, Vue } from 'vue-property-decorator';
import { mapState, mapGetters } from 'vuex';

/* ----------------------------------------
    Component
---------------------------------------- */

@Component({
    computed: {
        ...mapState(['popupContent']),
    },
})

/* ----------------------------------------
    Export
---------------------------------------- */

export default class Toast extends Vue {

}

